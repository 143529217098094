import { Controller } from "stimulus"
import { Modal, Toast } from "bootstrap"
import successKid from '../images/memes/success-kid.gif'
import takeMyMoney from '../images/memes/shut-up-meme.gif'
import mindBlown from '../images/memes/mind-blown.gif'
import highFive from '../images/memes/high-five.gif'
import pointingUp from '../images/memes/pointing-up.gif'
import happyDance from '../images/memes/happy-dance.gif'


export default class extends Controller {
  static targets = ["memeContainer", "messageTitle", "messageSubtext", "form"]
  
  MEMES = [
    {
      image: successKid,
      alt: "Success Kid Meme",
      title: "Be a hero to your industry friends! 💪",
      subtext: "Share VIN Match Pro and earn rewards"
    },
    {
      image: takeMyMoney,
      alt: "Take My Money Meme",
      title: "Know someone who needs better VIN data? 🎯",
      subtext: "We'll make it worth your while"
    },
    {
      image: mindBlown,
      alt: "Mind Blown Meme",
      title: "Let others discover the power of VIN Match Pro! 🌟",
      subtext: "Your referrals help the industry grow stronger"
    },
    {
      image: highFive,
      alt: "High Five Meme",
      title: "Team up with us to grow the community! 🤝",
      subtext: "Refer companies and earn rewards"
    },
    {
      image: pointingUp,
      alt: "Pointing Up Meme",
      title: "Know a company that could use this? ☝️",
      subtext: "Share the love and get rewarded"
    },
    {
      image: happyDance,
      alt: "Happy Dance Meme",
      title: "Spread the joy of accurate VIN data! 🎉",
      subtext: "Your referrals make everyone happy"
    }
  ]

  connect() {
    // Initialize modal
    const modalElement = document.getElementById('referralModal')
    this.modal = new Modal(modalElement)
    
    // Set up modal show event listener
    modalElement.addEventListener('show.bs.modal', this.setRandomMeme.bind(this))

    // Set up form submission handlers
    if (this.hasFormTarget) {
      const submitButton = this.formTarget.querySelector('button[type="submit"]')
      
      this.formTarget.addEventListener('ajax:before', () => {
        if (!this.formTarget.checkValidity()) {
          event.preventDefault()
          this.formTarget.classList.add('was-validated')
          return false
        }
        submitButton.disabled = true
        submitButton.innerHTML = '<span class="spinner-border spinner-border-sm me-2"></span>Submitting...'
      })

      this.formTarget.addEventListener('ajax:success', () => {
        console.log('Form submission successful')
        submitButton.disabled = false
        submitButton.innerHTML = 'Submit Referral'
        this.handleSuccessfulSubmission()
      })

      this.formTarget.addEventListener('ajax:error', () => {
        console.error('Form submission failed')
        submitButton.disabled = false
        submitButton.innerHTML = 'Submit Referral'
        alert('There was an error submitting your referral. Please try again.')
      })
    }
  }

  handleSuccessfulSubmission() {
    // First hide the modal
    if (this.modal) {
      this.modal.hide()
      // Also remove modal-open class and backdrop
      document.body.classList.remove('modal-open')
      const backdrop = document.querySelector('.modal-backdrop')
      if (backdrop) {
        backdrop.remove()
      }
    }

    // Reset the form
    this.formTarget.reset()
    this.formTarget.classList.remove('was-validated')

    // Show the toast after a short delay
    setTimeout(() => {
      // Create toast container if it doesn't exist
      let toastContainer = document.querySelector('.toast-container')
      if (!toastContainer) {
        toastContainer = document.createElement('div')
        toastContainer.className = 'toast-container position-fixed top-0 end-0 p-3'
        document.body.appendChild(toastContainer)
      }

      // Create toast element
      const toastElement = document.createElement('div')
      toastElement.className = 'toast align-items-center text-white bg-success border-0'
      toastElement.setAttribute('role', 'alert')
      toastElement.setAttribute('aria-live', 'assertive')
      toastElement.setAttribute('aria-atomic', 'true')
      toastElement.innerHTML = `
        <div class="d-flex">
          <div class="toast-body">
            Thank you for your referral!
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      `
      toastContainer.appendChild(toastElement)

      // Initialize and show toast
      try {
        const toast = new Toast(toastElement, {
          delay: 2000 // Show for 2 seconds
        })
        toast.show()

        // Clean up after hiding
        toastElement.addEventListener('hidden.bs.toast', () => {
          toastElement.remove()
          if (!toastContainer.hasChildNodes()) {
            toastContainer.remove()
          }
        })
      } catch (error) {
        console.error('Toast error:', error)
        alert('Thank you for your referral!')
      }
    }, 300)
  }

  disconnect() {
    if (this.modal) {
      this.modal.dispose()
    }
  }

  setRandomMeme() {
    const randomMeme = this.MEMES[Math.floor(Math.random() * this.MEMES.length)]
    
    if (this.hasMemeContainerTarget) {
      this.memeContainerTarget.innerHTML = `
        <img src="${randomMeme.image}" 
             alt="${randomMeme.alt}" 
             class="img-fluid" 
             style="max-height: 200px; object-fit: contain">
      `
    }
    
    if (this.hasMessageTitleTarget) {
      this.messageTitleTarget.innerHTML = randomMeme.title
    }
    
    if (this.hasMessageSubtextTarget) {
      this.messageSubtextTarget.innerHTML = randomMeme.subtext
    }
  }
} 