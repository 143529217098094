import { Controller } from "stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  static values = {
    show: Boolean
  }

  connect() {
    this.modal = new Modal(this.element)
    if (this.showValue) {
      this.modal.show()
    }
  }

  disconnect() {
    if (this.modal) {
      this.modal.dispose()
    }
  }
} 