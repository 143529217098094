import { Controller } from "stimulus"
import Dropdown from "bootstrap/js/src/dropdown"

export default class extends Controller {
  connect() {
    this.dropdown = new Dropdown(this.element.querySelector('[data-bs-toggle="dropdown"]'))
  }

  disconnect() {
    if (this.dropdown) {
      this.dropdown.dispose()
    }
  }

  toggle(event) {
    event.stopPropagation()
    this.dropdown.toggle()
  }
} 