import { Controller } from "stimulus"
import { Modal } from "bootstrap"

export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', this.handleSubmit.bind(this))
  }
  
  async handleSubmit(event) {
    event.preventDefault()
    
    if (!this.element.checkValidity()) {
      this.element.classList.add('was-validated')
      return
    }
    
    const formData = new FormData(this.element)
    const submitButton = this.element.querySelector('button[type="submit"]')
    const originalText = submitButton.innerHTML
    
    try {
      submitButton.disabled = true
      submitButton.innerHTML = '<span class="spinner-border spinner-border-sm me-2"></span>Submitting...'
      
      const jsonData = {
        referral: {
          company_name: formData.get('referral[company_name]'),
          contact_name: formData.get('referral[contact_name]'),
          phone: formData.get('referral[phone]'),
          email: formData.get('referral[email]'),
          notes: formData.get('referral[notes]')
        }
      }
      
      const response = await fetch(this.element.action, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(jsonData)
      })
      
      const data = await response.json()
      
      if (response.ok) {
        // Get modal instance using Bootstrap 5 API
        const modalElement = document.getElementById('referralModal')
        const modal = Modal.getInstance(modalElement)
        modal.hide()
        
        // Show toast notification
        const toastContainer = document.createElement('div')
        toastContainer.innerHTML = `
          <div class="toast-container position-fixed top-0 end-0 p-3">
            <div class="toast align-items-center text-white bg-success border-0" role="alert" aria-live="assertive" aria-atomic="true">
              <div class="d-flex">
                <div class="toast-body">
                  ${data.message}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>
            </div>
          </div>
        `
        document.body.appendChild(toastContainer)
        const toast = new bootstrap.Toast(toastContainer.querySelector('.toast'))
        toast.show()
        
        // Reset form
        this.element.reset()
        this.element.classList.remove('was-validated')
      } else {
        throw new Error(data.errors.join(', '))
      }
    } catch (error) {
      alert(`Error submitting referral: ${error.message}`)
    } finally {
      submitButton.disabled = false
      submitButton.innerHTML = originalText
    }
  }
} 